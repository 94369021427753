<template>
  <div>
    <div class="pt-5 text-h2 text-center">
      Пожалуйста подождите, идет проверка оплаты
    </div>
  </div>
</template>

<script>


export default {
  props: {
    orderNumber: {
      type: String,
      require: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    async paymentProcessingStatus() {
      let resp = await this.$axios.get(`/Other/ServiceStatus?orderNumber=${this.orderNumber}`)
      return resp.data
    }
  },
  async mounted() {
    await this.paymentProcessingStatus()
  }
}
</script>

<style>

</style>